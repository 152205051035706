import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = "https://server.naqar.net"

if(window.location.href.includes('localhost')){
  window.api = "http://127.0.0.1:3000"
}
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import CKEditor from '@ckeditor/ckeditor5-vue2';
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use( CKEditor );

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

window.convertToHijri = function(x){return x;}

var allowed = [
  "/subscribe",
  "/installed",
  "/updated",
  "/contact",
  "/support",
  "/help",
  "/docs",
  "/school_id_required"
]
setTimeout(function(){
  if(!localStorage.getItem("school_id") && !allowed.includes(window.location.pathname) && !window.location.href.includes("weeklyplan")){
    window.location = '/school_id_required'
  }
}, 3000)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
